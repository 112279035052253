import React, { useMemo, useState, useCallback, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { convertTo24HourUTC } from "utils/date";

createTheme("solarized", {
  background: {
    default: "transparent",
  },
});

function AllTicketTable({ ticketStatus, tableDataComplex }) {
  const [tickets, setTickets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
 // Sorting function for createdAt
 const sortByCreatedAt = (data) => {
  return data.sort((a, b) =>   new Date(b.createdAt) - new Date(a.createdAt)) ;
};

useEffect(() => {
  let filteredData = ticketStatus === "ALL" ? tableDataComplex : tableDataComplex.filter((item) => item.trx_status === ticketStatus);
  setTickets(sortByCreatedAt(filteredData));
}, [ticketStatus, tableDataComplex]);

useEffect(() => {
  console.log("state", selectedRows);
}, [selectedRows]);



  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const toggleActivation = (row) => {
    // Toggle activation logic here
    console.log(`Toggling activation for row with ID: ${row.id}`);
  };

  // {
  //     vaID:"9826387687",
  //     vaName: "Himanshu Yadav",
  //     subVaAccountNum:"7665875875",
  //     createdAt:"2024-04-17 10:03:48",
  //     updatedAt:"2024-04-17 10:03:48",
  //     openingBalance:"10000",
  //     closingBalance:"11000",
  //     chargeGST: 500
  //   },

  const columns = useMemo(
    () => [
      {
        name: <TH columnName="Refrence ID" />,
        selector: (row) => row.reference_id,
        sortable: true,
        cell: (row) => (
          <p
            className="text-sm font-bold text-navy-700 dark:text-white"
            style={{ width: "1500px" }}
          >
            {row.reference_id}
          </p>
        ),
      },
      {
        name: <TH columnName="Transaction ID" />,
        selector: (row) => row.transaction_id,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white" style={{ width: '1500px' }}>
            {row.transaction_id}
          </p>
        ),
      },
      {
        name: <TH columnName="Transaction Status" />,
        selector: (row) => row.trx_status,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.trx_status}
          </p>
        ),
      },

      {
        name: <TH columnName="VA ID" />,
        selector: (row) => row.SubVaId,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.SubVaId}
          </p>
        ),
      },
      {
        name: <TH columnName="UTR" />,
        selector: (row) => row.utr,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.utr}
          </p>
        ),
      },
      {
        name: <TH columnName="Account Holder Name" />,
        selector: (row) => row.account_holder_name,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.account_holder_name}
          </p>
        ),
      },

      {
        name: <TH columnName="Beneficiary Name" />,
        selector: (row) => row.beneficiaryName,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.beneficiaryName}
          </p>
        ),
      },
      {
        name: <TH columnName="Account Number" />,
        selector: (row) => row.account_number,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.account_number}
          </p>
        ),
      },
      {
        name: <TH columnName="Ifsc Code" />,
        selector: (row) => row.ifsc,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.ifsc}
          </p>
        ),
      },
      {
        name: <TH columnName="Amount" />,
        selector: (row) => row.amount,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.amount}
          </p>
        ),
      },
      {
        name: <TH columnName="Payment Mode" />,
        selector: (row) => row.payment_mode,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.payment_mode}
          </p>
        ),
      },

      {
        name: <TH columnName="Opening Balance" />,
        selector: (row) => row.opening_balance,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {Number(row.opening_balance).toFixed(2)}
          </p>
        ),
      },
      {
        name: <TH columnName="Closing Balance" />,
        selector: (row) => row.closing_balance,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {Number(row.closing_balance).toFixed(2)}
          </p>
        ),
      },
      {
        name: <TH columnName="Charge" />,
        selector: (row) => row.service_charge,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {Number(row.service_charge || 0).toFixed(2)}
          </p>
        ),
      },
      {
        name: <TH columnName="GST" />,
        selector: (row) => row.gst,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
           {Number(row.gst || 0).toFixed(2)}
          </p>
        ),
      },
      // {
      //   name: <TH columnName="Beneficiary Account Type" />,
      //   selector: (row) => row.beneAcType,
      //   sortable: true,
      //   cell: (row) => (
      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
      //       {row.beneAcType}
      //     </p>
      //   ),
      // },

      {
        name: <TH columnName="VA Account Name" />,
        selector: (row) => row.vaName,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.vaName}
          </p>
        ),
      },
      {
        name: <TH columnName="Va Account Number" />,
        selector: (row) => row.SubVaAccountNumber,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {row.SubVaAccountNumber}
          </p>
        ),
      },
      {
        name: <TH columnName="Created At" />,
        selector: (row) => row.createdAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.createdAt)}
          </p>
        ),
      },
      {
        name: <TH columnName="updated At" />,
        selector: (row) => row.updatedAt,
        sortable: true,
        cell: (row) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {convertTo24HourUTC(row.updatedAt)}
          </p>
        ),
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      data={tickets}
      selectableRows
      onSelectedRowsChange={handleChange}
      pagination
      theme="solarized"
    />
  );
}

export default AllTicketTable;

const TH = ({ columnName }) => {
  const capitalizedColumnName = columnName.toUpperCase();

  return (
    <th className=" pb-[10px] text-start dark:!border-navy-700">
      <p className="text-xs  text-gray-600">{capitalizedColumnName}</p>
    </th>
  );
};
