import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import ComplexTable from "views/admin/default/components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex.json";

import { FaTruck, FaWallet, FaRupeeSign } from "react-icons/fa";

import { LiaFileInvoiceSolid } from "react-icons/lia";

import { useEffect, useState } from "react";
import { getDashboardData } from "services/merchant.service";
import AllTicketTable from "./components/AllTicketTable";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [ticketStatus, setTicketStatus] = useState("ALL");

  useEffect(() => {
    getDashboardData().then((data) => {
      setDashboardData(data.data);
      console.log(data.data);
      localStorage.setItem("id", data.data?.wallet?._id);
    });
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"Account Balance"}
          subtitle={`₹ ${
            dashboardData?.wallet?.current_balance.toFixed(2) || 0
          }`}
        />
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"Service Charge"}
          subtitle={`₹ ${
            dashboardData?.debitList?.serviceChargePayoutAmount.toFixed(2) || 0
          }`}
        />
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"MID"}
          // subtitle2={`${dashboardData?.creditList?.successNoCredits||0}`}
          subtitle={`${dashboardData?.wallet?.merchant_vaId || ""}`}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Payout Successful"}
          subtitle2={`${dashboardData?.debitList?.successNoDebits || 0}`}
          subtitle={`₹ ${
            dashboardData?.debitList?.successPayoutAmount.toFixed(2) || 0
          }`}
        />
        <Widget
          icon={<FaWallet className="h-6 w-6" />}
          title={"Payout Failed"}
          subtitle2={`${dashboardData?.debitList?.failedNoDebits || 0}`}
          subtitle={`₹ ${
            dashboardData?.debitList?.failedPayoutAmount.toFixed(2) || 0
          }`}
        />
        <Widget
          icon={<FaRupeeSign className="h-6 w-6" />}
          title={"Payout Pending"}
          subtitle2={`${dashboardData?.debitList?.pendingNoDebits || 0}`}
          subtitle={`₹ ${
            dashboardData?.debitList?.pendingPayoutAmount.toFixed(2) || 0
          }`}
        />
      </div>

      <div className="mt-10">
        <AllTicketTable
          ticketStatus={ticketStatus}
          tableDataComplex={dashboardData?.latestDebits}
        />
      </div>
    </div>
  );
};

export default Dashboard;
